<template>
    <div>
        <!--Header-->
        <div class="d-flex ">
            <!--Back Button-->
            <app-btn
                @click.native="handleBack"
                class="mr-4"
                color="white"
                icon="arrowBack"
                icon-color="primary"
            />
            
            <page-title
                icon="accreditation"
                info="Create and manage your Accreditation Users."
                title="Accreditation"
            />
        </div>

        <v-divider class="mt-2" />

        <!--Passes Figures-->
        <div
            v-if="
                fullEventData?.accreditationEventData?.entityId &&
                $vuetify.breakpoint.width >= 600
            "
            class="mt-4"
            style="
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 16px;
            "
        >
            <!--Requested-->
            <div class="d-flex flex-column align-center black rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{
                    numberOfPassesRequested
                }}</app-text>
                <app-text color="white" size="small">Requested</app-text>
            </div>

            <!--Approved-->
            <div class="d-flex flex-column align-center green rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{
                    numberOfPassesApproved
                }}</app-text>
                <app-text color="white" size="small">Approved</app-text>
            </div>

            <!--Pending-->
            <div class="d-flex flex-column align-center orange rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{
                    numberOfPassesPending
                }}</app-text>
                <app-text color="white" size="small">Pending</app-text>
            </div>

            <!--Rejected-->
            <div class="d-flex flex-column align-center red rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{
                    numberOfPassesRejected
                }}</app-text>
                <app-text color="white" size="small">Rejected</app-text>
            </div>

            <!--Reserved-->
            <div class="d-flex flex-column align-center blue rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{
                    numberOfPassesReserved
                }}</app-text>
                <app-text color="white" size="small">Reserved</app-text>
            </div>
        </div>

        <!--Action Bar-->
        <div class="d-flex align-center mt-4">
            <!--Search-->
            <app-form-field
                form-type="textInput"
                append-icon="icons8-search"
                class="mr-4"
                :clearable="true"
                label="User Name"
                style="width: 100%"
                v-model.trim="searchByUserName"
            />

            <!--Add Button-->
            <!--<app-btn class="mr-4" icon="add" label="Add"/>-->

            <!--Filter Button - with a numbered badge-->
            <div style="position: relative">
                <app-btn
                    @click.native="toggleFiltersVisibility"
                    class="mr-4"
                    color="appWhite"
                    icon="filter"
                    icon-color="primary"
                />
                <span v-if="computedNumberOfActiveFilters > 0" class="badge">{{
                    computedNumberOfActiveFilters
                }}</span>
            </div>

            <!--More Actions Menu-->
            <more-actions-menu
                @emitMenuSelection="handleEmittedMenuSelection"
                :menuList="computedMenuOptions"
            />
        </div>

        <!--Clear Filters-->
        <div
            v-if="computedNumberOfActiveFilters > 0"
            class="d-flex justify-end mt-4"
        >
            <app-btn
                @click.native="clearFilters"
                icon="close"
                label="Clear Filters"
            />
        </div>

        <!--Getting Started Info-->
        <div
            v-if="!fullEventData?.accreditationEventData?.entityId"
            class="d-flex align-center justify-center"
            :style="
                $vuetify.breakpoint.width < 600
                    ? 'height: 50vh'
                    : 'height: 50vh'
            "
        >
            <page-start-information
                title="Getting Started"
                :points="['Configure this event', 'Set it to Live']"
            />
        </div>

        <!--Table-->
        <v-data-table
            v-if="
                $vuetify.breakpoint.width >= 600 &&
                fullEventData?.accreditationEventData?.entityId
            "
            class="appWhite rounded-lg mt-4"
            :headers="computedHeaders"
            :items="computedTableData"
        >
            <!--ID-->
            <template v-slot:item.entityId="{ item }">
                <app-text size="small">{{ item.entityId }}</app-text>
            </template>

            <!--Status-->
            <template v-slot:item.status="{ item }">
                <app-text
                    v-if="
                        item.passData?.accreditationPassStatus === 'Not Applied'
                    "
                    >-</app-text
                >
                <app-icon
                    v-else
                    :color="
                        getStatusIconConfig(
                            item.passData?.accreditationPassStatus
                        ).color
                    "
                    :icon="
                        getStatusIconConfig(
                            item.passData?.accreditationPassStatus
                        ).icon
                    "
                    size="32"
                    :title="item.passData?.accreditationPassStatus"
                />
            </template>

            <!--Is Printed-->
            <template v-slot:item.printed="{ item }">
                <app-icon
                    :color="
                        item.passData?.accreditationPassPrinted
                            ? 'green'
                            : 'greyD'
                    "
                    icon="print"
                    size="32"
                    :title="
                        item.passData?.accreditationPassPrinted
                            ? 'Printed'
                            : 'Not Printed'
                    "
                />
            </template>

            <!--UserName-->
            <template v-slot:item.userName="{ item }">
                <app-text size="small">{{ item.userData.userName }}</app-text>
            </template>

            <!--UserOrganisation-->
            <template v-slot:item.userOrganisation="{ item }">
                <app-text size="small">{{
                    item.organisationData.organisationName
                }}</app-text>
            </template>

            <!--UserPosition-->
            <template v-slot:item.userPosition="{ item }">
                <app-text size="small">{{
                    item.userData.userPosition
                }}</app-text>
            </template>

            <!--Action Button-->
            <template v-slot:item.action="{ item }">
                <app-icon
                    @click.native="handleUserSelection(item)"
                    class="cursorPointer"
                    color="primary"
                    icon="arrowForward"
                />
            </template>
        </v-data-table>

        <!--Mobile Cards-->
        <div v-if="$vuetify.breakpoint.width < 600">
            <!--No data message-->
            <app-text
                v-if="!computedTableData.length"
                class="text-center mt-4"
                color="grey9"
                >You have no Accreditation Events to view
            </app-text>

            <!--Cards-->
            <accreditation-users-mobile-card
                v-for="(item, index) in computedTableData"
                :key="index"
                @click.native="handleUserSelection(item)"
                class="mt-4"
                :card-data="item"
            />
        </div>

        <!--Filters Panel ------------------------------------------------------------------------------------------ -->
        <filter-panel
            :is-filters-panel-visible="isFiltersPanelVisible"
            @toggle="toggleFiltersVisibility"
        >
            <div class="pa-4">
                <!--Statuses-->
                <page-break-title title="Statuses" />
                <div
                    class="mt-4"
                    style="
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fit,
                            minmax(120px, 1fr)
                        );
                        grid-gap: 16px;
                    "
                >
                    <app-btn
                        @click.native="handleFilterStatusSelection('Pending')"
                        :block="true"
                        color="orange"
                        label="Pending"
                        :outlined="!filterByStatuses.includes('Pending')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Approved')"
                        :block="true"
                        color="green"
                        label="Approved"
                        :outlined="!filterByStatuses.includes('Approved')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Rejected')"
                        :block="true"
                        color="red"
                        label="Rejected"
                        :outlined="!filterByStatuses.includes('Rejected')"
                    />
                    <app-btn
                        @click.native="handleFilterStatusSelection('Reserved')"
                        :block="true"
                        color="blue"
                        label="Reserved"
                        :outlined="!filterByStatuses.includes('Reserved')"
                    />
                    <app-btn
                        @click.native="
                            handleFilterStatusSelection('Not Applied')
                        "
                        :block="true"
                        color="grey"
                        label="Not Applied"
                        :outlined="!filterByStatuses.includes('Not Applied')"
                    />
                </div>

                <!--Is Printed-->
                <page-break-title class="mt-8" title="Print Status" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :deletable-chips="true"
                        :items="['Printed', 'Not Printed']"
                        label="Print Status"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByPrintStatus"
                    />
                </div>

                <!--Organisation-->
                <page-break-title class="mt-8" title="Organisation" />
                <div class="mt-4">
                    <app-form-field
                        form-type="textInput"
                        :clearable="true"
                        label="Organisation"
                        v-model.trim="filterByOrganisation"
                    />
                </div>

                <!--Position-->
                <page-break-title class="mt-8" title="Position" />
                <div class="mt-4">
                    <app-form-field
                        form-type="textInput"
                        label="Position"
                        v-model.trim="filterByPosition"
                    />
                </div>
            </div>
        </filter-panel>
    </div>
</template>

<script>
import AccreditationUsersMobileCard from "@/views/accreditation/staff/accreditationUsers/accreditationUsersMobileCard/AccreditationUsersMobileCard.vue";

export default {
    name: "AccreditationUsers",

    components: { AccreditationUsersMobileCard },

    props: ["fullEventData", "selectedOrganisation"],

    data: () => ({
        isFiltersPanelVisible: false,
        filterByOrganisation: "",
        filterByPosition: "",
        filterByPrintStatus: [],
        filterByStatuses: [],
        isLoading: false,
        isReadOnly: false,
        numberOfPassesApproved: 0,
        numberOfPassesPending: 0,
        numberOfPassesRejected: 0,
        numberOfPassesRequested: 0,
        numberOfPassesReserved: 0,
        searchByUserName: "",
        selectedItem: {},
        tableHeaders: [
            {
                text: "ID",
                value: "id",
                align: "left",
                sortable: false,
                hidden: true,
            },
            {
                text: "Status",
                value: "status",
                align: "center",
                sortable: false,
                width: "48px",
            },
            {
                text: "Printed?",
                value: "printed",
                align: "center",
                sortable: false,
                width: "48px",
            },
            {
                text: "Name",
                value: "userName",
                align: "start",
                sortable: false,
            },
            {
                text: "Organisation",
                value: "userOrganisation",
                align: "start",
                sortable: false,
            },
            {
                text: "Position",
                value: "userPosition",
                align: "start",
                sortable: false,
            },
            {
                text: "",
                value: "action",
                align: "right",
                sortable: false,
                width: "48px",
            },
        ],
    }),

    computed: {
        /**
         * Computed Export CSV
         *
         * Return the data and headers for the CSV export
         *
         * @returns {{headers: {}, data: *[]}}
         */
        computedExportCSV() {
            const t = this;
            let data = [];
            let headers = {};
            const FULL_EVENT_DATA = t.$props.fullEventData;

            // Add the readable headers for the CSV columns
            headers = {
                entityId: "ID",
                eventName: "Event Name",
                eventDate: "Event Date",
                accreditationPassStatus: "Pass Status",
                accreditationPassPrinted: "Print Status",
                accreditationPassUserId: "User Name",
                accreditationPassOrganisationId: "Organisation",
                userPosition: "User Job Title",
            };

            // Add the data
            t.computedTableData.forEach((entry) => {
                const DATA_OBJECT = {
                    entityId: entry?.passData?.entityId || "",
                    eventName: FULL_EVENT_DATA?.eventData?.eventName || "",
                    eventDate:
                        t.MIX_formatDate(
                            FULL_EVENT_DATA?.eventData?.eventDate,
                            "numeric"
                        ) || "",
                    accreditationPassStatus:
                        entry?.passData?.accreditationPassStatus || "",
                    accreditationPassPrinted: entry?.passData
                        ?.accreditationPassPrinted
                        ? "Printed"
                        : "Not Printed",
                    accreditationPassUserId: entry?.userData?.userName || "",
                    accreditationPassOrganisationId:
                        entry?.organisationData?.organisationName || "",
                    userPosition: entry?.userData?.userPosition || "",
                };

                data.push(DATA_OBJECT);
            });

            return { headers, data };
        },

        /**
         * Computed Headers
         *
         * Remove hidden headers and only show the actions column to those with access.
         *
         * @returns an array of header objects
         */
        computedHeaders() {
            const t = this;
            let headers = t.tableHeaders;

            // Remove hidden headers
            headers = headers.filter((h) => !h.hidden);

            return headers;
        },

        /**
         * Computed Menu Options
         *
         * Return the menu options based on the event status.
         *
         * @returns {*[]} an array of menu options
         */
        computedMenuOptions() {
            const t = this;
            let menuOptions = [];

            menuOptions.push({ name: "Event Settings", icon: "settings" });
            menuOptions.push({ name: "Export", icon: "export" });

            // Only show these options if the event is live
            if (t.$props.fullEventData?.accreditationEventData?.entityId) {
                menuOptions.push({ name: "Print Manager", icon: "print" });
            }

            return menuOptions;
        },

        /**
         * Computed Number of Active Filters
         *
         * Count the number of active filters to display in the filters button badge.
         *
         * @returns {*}
         */
        computedNumberOfActiveFilters() {
            const t = this;
            return [
                t.filterByPosition ? 1 : 0,
                t.filterByPrintStatus.length,
                t.filterByStatuses.length,
            ].reduce((acc, curr) => acc + curr, 0);
        },

        /**
         * Computed Table Data
         *
         * Sort and return the table data.
         *
         * @returns {array[{}]} an array of objects
         */
        computedTableData() {
            const t = this;
            let passesData = [];

            // Only get data for selected organisation
            if (t.$props.selectedOrganisation) {
                passesData = [t.$props.selectedOrganisation];
            }
            let tableData = [];

            // Reset the figures
            t.numberOfPassesRequested = 0;
            t.numberOfPassesPending = 0;
            t.numberOfPassesApproved = 0;
            t.numberOfPassesRejected = 0;
            t.numberOfPassesReserved = 0;

            // Loop through the passesData and create a new object for each user to simplify passing data around
            passesData.forEach((item) => {
                const ORGANISATION_DATA = item.organisationData;
                item.userPassData.forEach((user) => {
                    const ITEM_OBJECT = {
                        organisationData: ORGANISATION_DATA,
                        passData: user.passData,
                        userData: user.userData,
                    };

                    // If the user's pass object is empty, add the status as "Not Applied"
                    if (!user.passData)
                        ITEM_OBJECT.passData = {
                            accreditationPassStatus: "Not Applied",
                        };

                    tableData.push(ITEM_OBJECT);
                });
            });

            // Search by UserName
            if (t.searchByUserName) {
                const SEARCH_BY_USERNAME = t.searchByUserName?.toUpperCase();
                tableData = tableData.filter((item) => {
                    const USER_NAME =
                        item.userData?.userName?.toUpperCase() || "";
                    return USER_NAME.includes(SEARCH_BY_USERNAME);
                });
            }

            // Filter by Status
            if (t.filterByStatuses.length)
                tableData = tableData.filter((item) =>
                    t.filterByStatuses.includes(
                        item.passData?.accreditationPassStatus
                    )
                );

            // Filter by Print Status
            if (t.filterByPrintStatus.length)
                tableData = tableData.filter((item) =>
                    t.filterByPrintStatus.includes(
                        item.passData?.accreditationPassPrinted
                            ? "Printed"
                            : "Not Printed"
                    )
                );

            // Filter by Organisation
            if (t.filterByOrganisation) {
                const SEARCH_BY_ORGANISATION =
                    t.filterByOrganisation?.toUpperCase();
                tableData = tableData.filter((item) => {
                    const USER_ORGANISATION =
                        item.organisationData?.organisationName?.toUpperCase() ||
                        "";
                    return USER_ORGANISATION.includes(SEARCH_BY_ORGANISATION);
                });
            }

            // Filter by Position
            if (t.filterByPosition) {
                const SEARCH_BY_POSITION = t.filterByPosition?.toUpperCase();
                tableData = tableData.filter((item) => {
                    const USER_POSITION =
                        item.userData?.userPosition?.toUpperCase() || "";
                    return USER_POSITION.includes(SEARCH_BY_POSITION);
                });
            }

            // Increment the Status figures
            tableData.forEach((item) => {
                if (
                    ["Pending", "Approved", "Rejected", "Reserved"].includes(
                        item.passData?.accreditationPassStatus
                    )
                )
                    t.numberOfPassesRequested++;
                if (item.passData?.accreditationPassStatus === "Pending")
                    t.numberOfPassesPending++;
                if (item.passData?.accreditationPassStatus === "Approved")
                    t.numberOfPassesApproved++;
                if (item.passData?.accreditationPassStatus === "Rejected")
                    t.numberOfPassesRejected++;
                if (item.passData?.accreditationPassStatus === "Reserved")
                    t.numberOfPassesReserved++;
            });

            // Sort by UserName
            tableData = tableData.sort((a, b) =>
                a.userData.userName > b.userData.userName ? 1 : -1
            );

            return tableData;
        },
    },

    methods: {
        /**
         * Clear Filters
         *
         * Clear all the filters.
         */
        clearFilters() {
            const t = this;

            t.filterByPosition = "";
            t.filterByPrintStatus = [];
            t.filterByStatuses = [];
        },

        /**
         * Export Data
         *
         * Export the data to CSV.
         */
        exportData() {
            const t = this;

            t.MIX_exportDocuments(
                t.computedExportCSV.headers,
                "Accreditation",
                t.computedExportCSV.data
            );
        },

        /**
         * Get Status Icon Config
         *
         * Return the icon and color for the status.
         *
         * @param status {string} the status
         * @returns {{color: string, icon: string}}
         */
        getStatusIconConfig(status) {
            let icon = "";
            let color = "";

            if (status === "Pending") {
                icon = "pending";
                color = "orange";
            }

            if (status === "Approved") {
                icon = "success";
                color = "green";
            }

            if (status === "Rejected") {
                icon = "cancel";
                color = "red";
            }

            if (status === "Reserved") {
                icon = "reserved";
                color = "blue";
            }

            return { icon, color };
        },

        /**
         * Handle Emitted Menu Selection
         *
         * Take the emitted selection from the menu, and call the relevant function.
         *
         * @param selection a string-based menu option
         */
        handleEmittedMenuSelection(selection) {
            const t = this;

            if (selection === "Event Settings")
                t.$emit("openEventSettings", t.$props.fullEventData);
            if (selection === "Export") t.exportData();
            if (selection === "Print Manager")
                t.$emit("openPrintManager", t.$props.fullEventData);
        },

        /**
         * Handle Filter Status Selection
         *
         * Add or remove the selected status from the filterStatuses array.
         *
         * @param status {string} the status to add or remove
         */
        handleFilterStatusSelection(status) {
            const t = this;

            if (t.filterByStatuses.includes(status))
                t.filterByStatuses = t.filterByStatuses.filter(
                    (s) => s !== status
                );
            else t.filterByStatuses.push(status);
        },

        /**
         * Handle User Selection
         *
         * Emit the selected user to the parent for saving
         * .
         * @param item {object} the selected user
         */
        handleUserSelection(item) {
            const t = this;

            t.selectedItem = item;

            t.$emit("selectedUser", {
                selectedUser: item,
                formType: "PassForm",
            });
        },

        /**
         * Toggle Filters Visibility
         *
         * Toggle the visibility of the filters panel.
         */
        toggleFiltersVisibility() {
            const t = this;

            t.isFiltersPanelVisible = !t.isFiltersPanelVisible;
        },
        /**
         * Handle Back
         * 
         * Navigate back to organisations view
         */
        handleBack() {
            this.$emit('backToEvents');
        },
    },
};
</script>

<style scoped></style>
